import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { PostStore } from './post-store'
import { walletStore } from './wallet-store'

export class DraftPostsStore {
  @observable posts: PostStore[] = []
  @observable filter: any = { _limit: 9, _start: 0 }
  @observable total = 0
  @observable loadingMore = false
  @observable loadState: any = { _limit: 9, _start: 0 }
  @observable fetching = false

  private _disposers: IReactionDisposer[]

  constructor(filter) {
    this.fetchPosts(filter)
    this.fetchTotalPost(filter)
    this.loadState = { ...filter }
    this._disposers = [
      reaction(
        () => this.filter,
        (filter) => {
          this.fetchPosts(filter)
          this.fetchTotalPost(filter)
          this.changeLoadState({ ...filter, _limit: 9, _start: 0 })
        }
      ),
    ]
  }

  destroy() {
    this.posts?.forEach((postStore) => postStore.destroy())
    this._disposers.forEach((d) => d())
  }

  @action.bound changeFilter(filter) {
    this.filter = { ...filter }
  }

  @action.bound changeLoadState(filter) {
    this.loadState = { ...this.loadState, ...filter }
  }

  @asyncAction *fetchTotalPost(filter) {
    try {
      this.total = yield apiService.posts.count({ ...filter, status: 'draft', profile: walletStore.userProfile?._id })
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *fetchPosts(filter: any) {
    try {
      this.fetching = true
      const posts = yield apiService.posts.fetchDraftPosts({ ...this.filter, ...filter })
      this.posts = posts.map((post) => {
        const postStore = new PostStore(post)
        return postStore
      })
      return posts
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.fetching = false
    }
  }

  @asyncAction *loadMore() {
    try {
      this.loadingMore = true
      this.loadState = { ...this.loadState, _start: this.loadState._start + this.loadState._limit }
      const postMore = yield apiService.posts.fetchPosts({ ...this.loadState })
      const newPost = postMore.map((post) => new PostStore(post))
      const currentPost = this.posts
      this.posts = [...currentPost, ...newPost]
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.loadingMore = false
    }
  }

  @computed get canLoadMorePost() {
    if (this.posts.length < this.total) return true
    else return false
  }
}
