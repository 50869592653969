import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { ProfileModel } from '@/models/profile-model'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { postController } from '@/modules/common/dialogs/post/post-controller'
import { apiService } from '@/services/api-services'
import { DraftPostsStore } from '@/stores/draft-posts-store'
import { action, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
export class DraftViewModel {
  @observable posts?: DraftPostsStore
  @observable userProfile?: ProfileModel
  @observable display: 'grid' | 'list' = 'list'
  @observable fetching = false
  private _disposers: IReactionDisposer[]

  constructor() {
    this.init()

    this._disposers = [
      reaction(
        () => createPostController.completedUpdateType,
        (type) => {
          if (type) {
            createPostController.changeCompletedUpdateType(undefined)
            this.fetchPosts()
          }
        }
      ),
      reaction(
        () => postController.completeUpdateType,
        (type) => {
          if (type) {
            postController.changeCompleteUpdateType(undefined)
            this.fetchPosts()
          }
        }
      ),
    ]
  }

  destroy() {
    this._disposers.forEach((d) => d())
    this.posts?.destroy()
  }

  @asyncAction *init() {
    try {
      this.fetching = true
      const userId = appProvider.router.currentRoute.params.userId
      const userProfiles = yield apiService.profiles.find({ unique_id: userId, _limit: 1 })
      if (userProfiles[0]) {
        this.userProfile = userProfiles[0]
        yield this.fetchPosts()
      } else {
        snackController.commonError('Can not find user profile')
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.fetching = false
    }
  }

  @asyncAction *publicDraftPost(item: any) {
    try {
      item.post.publishing = true
      const res = yield apiService.posts.publishDraftPost({ postId: item.post.id })
      if (res) {
        snackController.success('Your post have published')
        if (this.posts?.posts) this.posts.posts = this.posts?.posts?.filter((item) => item.post.id !== item.post.id)
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.post.publishing = false
    }
  }

  @action.bound changeDisplay(value: 'list' | 'grid') {
    this.display = value
  }

  @asyncAction *fetchPosts() {
    const filter = { _limit: 12, _start: 0, profile: this.userProfile?._id }
    this.posts = new DraftPostsStore(filter)
  }
}
