
















import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { DraftViewModel } from '../viewmodels/draft-viewmodel'

@Observer
@Component({
  components: {
    'post-list': () => import('./components/draft/post-list.vue'),
  },
})
export default class Post extends Vue {
  @Provide() vm = new DraftViewModel()
  destroyed() {
    this.vm.destroy()
  }

  @Watch('$route', { immediate: true }) tabChange(val) {
    const hash = this.$router.currentRoute.hash
    if (hash) {
      if (hash === '#draft') {
        setTimeout(() => {
          document.getElementById(`list-draft`)?.scrollIntoView({ behavior: 'smooth' })
        }, 300)
      }
    }
  }
}
